import JSONfn from 'json-fn'
import * as tweenly from 'tweenly'
import { AssetT } from '../store/slices/assets.slice'
import { createJSONparams } from './file.helpers'
import { replaceSpacesWithDashes } from './string.helpers'

export const generalScripts = (
    objects: AnySceneObjectT[],
    assets: AssetT[],
    videoPanelAnimations: AnimationI[],
    canvas: CanvasI
) => {
    const jsonDictionaryData: string = JSON.parse(
        tweenly.checkAndTrimDollars(createJSONparams(objects, 'code'))
    )
    const processObjectData = (objects: AnySceneObjectT[]): string => {
        const objectArray: {
            id: string
            name: string
            type: string
            top: number
            left: number
            width: number
            height: number
            textSizing: string
            whitespace: string
        }[] = []

        objects.forEach((object: AnySceneObjectT) => {
            const widthStyle: SimpleStyleT | undefined = object.styles.find(
                (style) => style.property === 'width'
            )
            const heightStyle: SimpleStyleT | undefined = object.styles.find(
                (style) => style.property === 'height'
            )
            const topStyle: SimpleStyleT | undefined = object.styles.find(
                (style) => style.property === 'top'
            )
            const leftStyle: SimpleStyleT | undefined = object.styles.find(
                (style) => style.property === 'left'
            )

            const textSizing: AttributeT | undefined = object.attributes
                ? object.attributes.find((attribute) => attribute.property === 'textSizing')
                : undefined

            const whiteSpace: SimpleStyleT | undefined = object.styles
                ? object.styles.find((style) => style.property === 'whiteSpace')
                : undefined

            objectArray[objectArray.length] = {
                id: object.id,
                name: replaceSpacesWithDashes(object.title),
                type: object.type,
                top: topStyle?.value,
                left: leftStyle?.value,
                width: widthStyle?.value,
                height: heightStyle?.value,
                textSizing: textSizing?.value,
                whitespace: whiteSpace?.value,
            }
        })
        return JSONfn.stringify(objectArray)
    }
    const objectData = processObjectData(objects)

    return `
        window.tweenly.processGeneralScript(${objectData}, ${JSONfn.stringify(
        assets
    )}, ${JSONfn.stringify(jsonDictionaryData)}, ${JSONfn.stringify(
        videoPanelAnimations
    )}, ${JSONfn.stringify(canvas)})
        checkAndTrimDollars = window.tweenly.checkAndTrimDollars
        fetchAsset = window.tweenly.fetchAsset
        refreshAs = window.tweenly.refreshAs
        getObjectByClassName = window.tweenly.getObjectByClassName
        getObjectTextWidth = window.tweenly.getObjectTextWidth
        getTextFromJSONData = window.tweenly.getTextFromJSONData
        initTextScaling = window.tweenly.initTextScaling
        processField = window.tweenly.processField
        processGeneralScript = window.tweenly.processGeneralScript
        replaceAttributeToURL = window.tweenly.replaceAttributeToURL
        setDynamicImageUrls = window.tweenly.setDynamicImageUrls
        setDynamicTexts = window.tweenly.setDynamicTexts
        setImage = window.tweenly.setImage
        setImages = window.tweenly.setImages
        setObjectScaling = window.tweenly.setObjectScaling
        setText = window.tweenly.setText
        setTextFromJSONData = window.tweenly.setTextFromJSONData
        setTextThroughJSONData = window.tweenly.setTextThroughJSONData
        setTexts = window.tweenly.setTexts
        updateDateAndTime = window.tweenly.updateDateAndTime
        xml2json = window.tweenly.xml2json
        `
}

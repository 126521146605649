import { useMemo } from 'react'
import { Permission } from '../constants/permission.enum'
import { Role } from '../constants/role.enum'
import { CompanyT } from '../store/slices/company.slice'
import { GraphicAsset } from '../store/slices/graphics.slice'
import { useCompanyStats } from './useCompanyStats'
import { useAppSelector } from './useRedux'
import { useSession } from './useSession'

export const useVerifyPermission = (graphic: GraphicAsset, selectedCompany?: CompanyT | null) => {
    const user = useAppSelector((state) => state.user.value)
    const { hasPermission } = useSession()
    const { exported: exportsInCompany } = useCompanyStats({ id: selectedCompany?._id })

    //main const
    const isMyGraphic = graphic.userId === user._id //user create graphic
    const isPartOfWorkspace = graphic.company?.usersRef.some((u) => u.id === user._id) //user is part of workspace
    const isWorkspaceAdmin = graphic.company?.usersRef.some(
        (u) => u.id === user._id && u.role >= Role.Admin //user is Admin of workspace
    )
    const isUserActiveInCompany = selectedCompany?.usersRef.some((u) => u.id === user._id)

    const isVerify = graphic.company?.expired && graphic.company?.expired.getTime() > Date.now()

    const isSelectedCompanyVerify =
        selectedCompany?.expired && selectedCompany.expired.getTime() > Date.now()

    const notEnoughCoins = !!(
        !selectedCompany?.isPrivate &&
        selectedCompany?.coins &&
        selectedCompany?.coins < graphic.price
    )

    //graphic is exported and in private workspace ... OR
    //graphic is not exported and in private workspace but number of exports is less then two
    const isPrivateAndUnderTwoExports =
        (graphic.isExported && selectedCompany?.isPrivate) ||
        (!graphic.isExported && selectedCompany?.isPrivate && exportsInCompany < 2)

    //permission const
    const canCreateNew = useMemo(() => {
        if (selectedCompany) {
            if (
                (isSelectedCompanyVerify && isUserActiveInCompany) ||
                hasPermission(Permission.Graphics) ||
                selectedCompany.isPrivate
            ) {
                return true
            } else {
                return false
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany])
    const canUpdate =
        (isVerify && isUserActiveInCompany && !graphic.isPublished) || //company is verified, user is Active for selectedCompany and graphic is not Published
        (!isVerify && graphic.company?.isPrivate && !graphic.isPublished) || //user is not verified, graphic is in private company and graphic is not published
        hasPermission(Permission.Graphics) //can Update when user is superAdmin

    const canDuplicate =
        (isUserActiveInCompany && isVerify) || //company is verified and user is active
        (!isVerify && graphic.company?.isPrivate) || //company is not verified and company is Private
        hasPermission(Permission.Graphics) //user is superAdmin

    const canPublishUnPublish =
        (isVerify && (isMyGraphic || isWorkspaceAdmin)) || //company is verified and graphic is final version and it is my graphic or user is Admin of workspace
        hasPermission(Permission.Graphics) //user is superAdmin and graphic is final version

    const canExport =
        isPrivateAndUnderTwoExports ||
        (isVerify && isUserActiveInCompany) ||
        hasPermission(Permission.Graphics)

    const canDelete = !graphic.isPublished && (isMyGraphic || hasPermission(Permission.Graphics))

    const canPurchase = useMemo(() => {
        if (selectedCompany) {
            //selected workspace is not expired
            let notExpired =
                selectedCompany?.expired && selectedCompany?.expired.getTime() > Date.now()
            //is user admin of selected workspace
            let userIsAdminOfWorkspace = selectedCompany?.usersRef.some(
                (u) => u.id === user._id && u.role >= Role.Admin
            )
            let selectedCompanyOwnGraphic = graphic.companyId === selectedCompany._id //selected company created and own this graphic

            if (
                !selectedCompanyOwnGraphic && //selected company DO NOT created and own this graphic
                isUserActiveInCompany && //user is active in selected company
                selectedCompany.coins >= graphic.price && //enough coins
                ((notExpired && userIsAdminOfWorkspace) || //verified, not expired ended date, user is admin of selected workspace
                    (selectedCompany?.isPrivate && graphic.price === 0)) //personal workspace
                // (isWorkspaceAdmin && isVerified && notExpired && userIsAdminOfWorkspace) || //verified
                // (isWorkspaceAdmin && selectedCompany?.isPrivate && graphic.price === 0) //personal workspace
            ) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany])

    return {
        isMyGraphic,
        isWorkspaceAdmin,
        isPartOfWorkspace,
        isVerify,
        canDuplicate,
        canCreateNew,
        canUpdate,
        canDelete,
        canPublishUnPublish,
        canPurchase,
        canExport,
        notEnoughCoins,
    }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { del } from '../../helpers/ajax.helpers'
import { createObject } from '../../helpers/creators.helpers'
import { S3_PREFIX } from '../../helpers/file.helpers'
import { sortObjects } from '../../helpers/object.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { setSelectedObjectIdsAction } from './activeObject.slice'
import { addAnimationBaseAction } from './objects.slice/animation/addAnimationBaseAction'
import {
    AddAnimationT,
    CreateSequenceAnimationsT,
    UpdateOffsetAnimationsT,
    UpdateOffsetAnimationT,
} from './objects.slice/animation/animationTypes'
import { createSequenceAnimationsBaseAction } from './objects.slice/animation/createSequenceAnimationsBaseAction'
import { deleteAnimationsBaseAction } from './objects.slice/animation/deleteAnimationsBaseAction'
import { duplicateAnimationsBaseAction } from './objects.slice/animation/duplicateAnimationsBaseAction'
import { durationAnimationBaseAction } from './objects.slice/animation/durationAnimationBaseAction'
import { durationAnimationsBaseAction } from './objects.slice/animation/durationAnimationsBaseAction'
import { moveOffsetAnimationsBaseAction } from './objects.slice/animation/moveOffsetAnimationsBaseAction'
import { relativeDurationAnimationBaseAction } from './objects.slice/animation/relativeDurationAnimationBaseAction'
import { relativeDurationAnimationsBaseAction } from './objects.slice/animation/relativeDurationAnimationsBaseAction'
import { startOffsetAnimationBaseAction } from './objects.slice/animation/startOffsetAnimationBaseAction'
import { startOffsetAnimationsBaseAction } from './objects.slice/animation/startOffsetAnimationsBaseAction'
import { updateAnimationsBaseAction } from './objects.slice/animation/updateAnimationBaseAction'
import { createImageBaseAction } from './objects.slice/object/createImageBaseAction'
import { duplicateObjectsBaseAction } from './objects.slice/object/duplicateObjectsBaseAction'
import { moveSelectedHereBaseAction } from './objects.slice/object/moveSelectedHereBaseAction'
import { moveSelectedToRootBaseAction } from './objects.slice/object/movetSelectedToRootBaseAction'
import { objectNodeUpdate } from './objects.slice/object/objectNodeUpdate'
import {
    CreateImageT,
    PayloadAnimationT,
    PayloadChildT,
    PayloadIndexT,
    PayloadObjDelT,
    PayloadObjectsAndAnimations,
    PayloadObjectsT,
    PayloadObjectT,
    PayloadObjT,
    PayloadParentT,
    PayloadStyledT,
    UpdateObjectLockAspectRation,
    UpdateObjectsDifferentStyles,
    UpdateObjectsStyle,
} from './objects.slice/object/objectTypes'
import { updateObjectLockAspectRationPropertyBaseAction } from './objects.slice/object/updateObjectLockAspectRationPropertyBaseAction'
import { updateObjectsDifferentStylesProperty } from './objects.slice/object/updateObjectsDifferentStylesProperty'
import { updateObjectsPosition } from './objects.slice/object/updateObjectsPosition'
import { updateObjectsStyleProperty } from './objects.slice/object/updateObjectsStyleProperty'
import {
    defaultObject,
    uploadObjectsAndAnimationsBaseAction,
} from './objects.slice/object/uploadObjectsAndAnimationsBaseAction'

export type ObjectsStateT = {
    value: Record<string, AnySceneObjectT>
    tmpIds: string[]
}

const initialState: ObjectsStateT = {
    value: {},
    tmpIds: [],
}

//////////////////animations Types////////////
export type AnimationAddT = {
    animation: AnimationI
}
export type AnimationUpdT = {
    id: string
    objectId: string
    property: string
    value?: any
}

export type AnimationsT = {
    animations: AnimationI[]
}

const objectNodeUpdIndex = (stateObject: any, action: PayloadIndexT): AnySceneObjectT => {
    return {
        ...stateObject,
        index: action.index,
    }
}

const objectNodeUpdParentId = (stateObject: any, action: PayloadParentT): AnySceneObjectT => {
    return {
        ...stateObject,
        parentId: action.parentId,
    }
}

const objectNodeAddChildId = (stateObject: any, action: PayloadChildT): AnySceneObjectT => {
    return {
        ...stateObject,
        childIds: [...stateObject.childIds, action.childId],
    }
}

const objectNodeDelChildId = (stateObject: any, action: PayloadChildT): AnySceneObjectT => {
    return {
        ...stateObject,
        childIds: stateObject.childIds.filter((id: string) => id !== action.childId),
    }
}

const objectNodeDelAnimationId = (stateObject: any, action: PayloadAnimationT): AnySceneObjectT => {
    return {
        ...stateObject,
        animationIds: stateObject.animationIds.filter((id: string) => id !== action.animationId),
    }
}

const objectNodeAddGroupStyleId = (stateObject: any, action: PayloadStyledT): AnySceneObjectT => {
    return {
        ...stateObject,
        styleIds: [...stateObject.groupStyleIds, action.styleId],
    }
}

const objectNodeDelGroupStyleId = (stateObject: any, action: PayloadStyledT): AnySceneObjectT => {
    return {
        ...stateObject,
        styleIds: stateObject.styleIds.filter((id: string) => id !== action.styleId),
    }
}

export const getAllDescendantIds = (state: ObjectsStateT, nodeId: string): any => {
    return state.value[nodeId].childIds.reduce((acc: any, childId: string) => {
        return [...acc, childId, ...getAllDescendantIds(state, childId)]
    }, [])
}

const deleteMany = (state: ObjectsStateT, ids: string[]) => {
    //state = { ...state }
    ids.forEach((id) => {
        if (state) delete state.value[id]
    })
    return state
}

export const objectsSlice = createSlice({
    name: 'objects',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        pushTmpIds: (state, action: PayloadAction<string[]>) => {
            state.tmpIds = [...state.tmpIds, ...action.payload]
        },
        resetTmpIds: (state) => {
            state.tmpIds = []
        },
        uploadObjectsAndAnimationsAction: (
            state,
            action: PayloadAction<PayloadObjectsAndAnimations>
        ) => {
            uploadObjectsAndAnimationsBaseAction(state, action)
        },
        addObjAction: (state, action: PayloadAction<PayloadObjectT>) => {
            const id: string = action.payload.object.id
            state.value = {
                ...state.value,
                [id]: action.payload.object,
            }
        },
        addMultiObjAction: (state, action: PayloadAction<PayloadObjectsT>) => {
            action.payload.objects.forEach((object: AnySceneObjectT) => {
                state.value = {
                    ...state.value,
                    [object.id]: object,
                }
                if (object.parentId) {
                    state.value = {
                        ...state.value,
                        [object.parentId]: objectNodeAddChildId(state.value[object.parentId], {
                            id: object.parentId,
                            childId: object.id,
                        }),
                    }
                }
            })
        },
        delMultiObjAction: (state, action: PayloadAction<PayloadObjectsT>) => {
            action.payload.objects.forEach((object: AnySceneObjectT) => {
                if (object.parentId) {
                    state.value = {
                        ...state.value,
                        [object.parentId]: objectNodeDelChildId(state.value[object.parentId], {
                            id: object.parentId,
                            childId: object.id,
                        }),
                    }
                }
                const descendantIds = getAllDescendantIds(state, object.id)

                state = deleteMany(state, [object.id, ...descendantIds])
                //TO DO - after union objects and animations - dispatch(delAnimationsAction({ animationIds }))
            })
        },
        updObjectAction: (state, action: PayloadAction<PayloadObjT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeUpdate(state.value[id], action.payload),
            }
        },
        updateObjectsStylePropertyAction: (state, action: PayloadAction<UpdateObjectsStyle>) => {
            updateObjectsStyleProperty(state, action)
        },
        updateObjectsDifferentStylesPropertyAction: (
            state,
            action: PayloadAction<UpdateObjectsDifferentStyles[]>
        ) => {
            updateObjectsDifferentStylesProperty(state, action)
        },
        updateObjectsPositionAction: (
            state,
            action: PayloadAction<UpdateObjectsDifferentStyles[]>
        ) => {
            updateObjectsPosition(state, action)
        },
        updateObjectLockAspectRationPropertyAction: (
            state,
            action: PayloadAction<UpdateObjectLockAspectRation>
        ) => {
            updateObjectLockAspectRationPropertyBaseAction(state, action)
        },
        duplicateObjectsAction: (state, action: PayloadAction<AnySceneObjectT[]>) => {
            duplicateObjectsBaseAction(state, action)
        },
        updIndexAction: (state, action: PayloadAction<PayloadIndexT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeUpdIndex(state.value[id], action.payload),
            }
        },
        updParentIdAction: (state, action: PayloadAction<PayloadParentT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeUpdParentId(state.value[id], action.payload),
            }
        },
        addChildIdAction: (state, action: PayloadAction<PayloadChildT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeAddChildId(state.value[id], action.payload),
            }
        },
        delChildIdAction: (state, action: PayloadAction<PayloadChildT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeDelChildId(state.value[id], action.payload),
            }
        },
        delAnimationIdAction: (state, action: PayloadAction<PayloadAnimationT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeDelAnimationId(state.value[id], action.payload),
            }
        },
        addGroupStyleIdAction: (state, action: PayloadAction<PayloadStyledT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeAddGroupStyleId(state.value[id], action.payload),
            }
        },
        delGroupStyleIdAction: (state, action: PayloadAction<PayloadStyledT>) => {
            const id = action.payload.id
            state.value = {
                ...state.value,
                [id]: objectNodeDelGroupStyleId(state.value[id], action.payload),
            }
        },
        delObjectAction: (state, action: PayloadAction<PayloadObjDelT>) => {
            const id = action.payload.id
            const descendantIds = getAllDescendantIds(state, id)

            state = deleteMany(state, [id, ...descendantIds])
        },
        clearObjectsAction: (state) => {
            state.value = {}
        },
        moveSelectedHereAction: (
            state,
            action: PayloadAction<{ object: AnySceneObjectT; parent: AnySceneObjectT }>
        ) => {
            moveSelectedHereBaseAction(state, action)
        },
        moveSelectedToRootAction: (state, action: PayloadAction<{ object: AnySceneObjectT }>) => {
            moveSelectedToRootBaseAction(state, action)
        },

        /////////////////////////////animations action/////////////////////////////
        addAnimationAction: (state, action: PayloadAction<AddAnimationT>) => {
            addAnimationBaseAction(state, action)
        },
        updateAnimationAction: (state, action: PayloadAction<AnimationUpdT>) => {
            updateAnimationsBaseAction(state, action)
        },
        createImageAction: (state, action: PayloadAction<CreateImageT>) => {
            createImageBaseAction(state, action)
        },
        createSequenceAnimationsAction: (
            state,
            action: PayloadAction<CreateSequenceAnimationsT>
        ) => {
            createSequenceAnimationsBaseAction(state, action)
        },
        durationAnimationAction: (state, action: PayloadAction<UpdateOffsetAnimationT>) => {
            durationAnimationBaseAction(state, action)
        },
        durationAnimationsAction: (state, action: PayloadAction<UpdateOffsetAnimationsT>) => {
            durationAnimationsBaseAction(state, action)
        },
        relativeDurationAnimationAction: (state, action: PayloadAction<UpdateOffsetAnimationT>) => {
            relativeDurationAnimationBaseAction(state, action)
        },
        relativeDurationAnimationsAction: (
            state,
            action: PayloadAction<UpdateOffsetAnimationsT>
        ) => {
            relativeDurationAnimationsBaseAction(state, action)
        },
        startOffsetAnimationAction: (state, action: PayloadAction<UpdateOffsetAnimationT>) => {
            startOffsetAnimationBaseAction(state, action)
        },
        startOffsetAnimationsAction: (state, action: PayloadAction<UpdateOffsetAnimationsT>) => {
            startOffsetAnimationsBaseAction(state, action)
        },
        moveOffsetAnimationsAction: (state, action: PayloadAction<UpdateOffsetAnimationsT>) => {
            moveOffsetAnimationsBaseAction(state, action)
        },
        duplicateAnimationsAction: (state, action: PayloadAction<AnimationsT>) => {
            duplicateAnimationsBaseAction(state, action)
        },
        deleteAnimationsAction: (state, action: PayloadAction<AnimationsT>) => {
            deleteAnimationsBaseAction(state, action)
        },
    },
})

export const createImageObjectAction =
    (data: CreateImageT, onCreate: (id: string) => void): AppThunkT =>
    async (dispatch) => {
        dispatch(createImageAction(data))
        onCreate(data.object.id)
    }

export const updateObjectAnimationAction =
    (data: AnimationUpdT, onRefresh: () => void): AppThunkT =>
    async (dispatch) => {
        dispatch(updateAnimationAction(data))
        onRefresh()
    }

export const duplicateObjects =
    (data: AnySceneObjectT[]): AppThunkT =>
    async (dispatch) => {
        const newData = data.map((x) => {
            const newId = 'i' + uuidv4()
            return { ...x, id: newId }
        })
        dispatch(duplicateObjectsAction(newData))
        dispatch(setSelectedObjectIdsAction({ objectIds: newData.map((o) => o.id) }))
    }

export const addObjectAction =
    (object: AnySceneObjectT): AppThunkT =>
    (dispatch, getState) => {
        return dispatch(addObjAction({ object: object }))
    }

export const addMultiObjectsAction =
    (objects: AnySceneObjectT[]): AppThunkT =>
    (dispatch) => {
        return dispatch(addMultiObjAction({ objects: objects }))
    }
export const delMultiObjectsAction =
    (objects: AnySceneObjectT[]): AppThunkT =>
    (dispatch) => {
        return dispatch(delMultiObjAction({ objects: objects }))
    }

export const checkIndexes =
    (parentId: string | null): AppThunkT =>
    (dispatch, getState) => {
        const { objects } = selectUndoable(getState())

        // pokud nejsou zadne objekty ke kontrole
        if (Object.keys(objects.value).length === 0) return

        let siblings: AnySceneObjectT[]

        if (parentId) {
            const parentChildIds: string[] = selectObjectById(objects, parentId).childIds
            // pokud nejsou zadne indexy ke kontrole
            if (parentChildIds.length === 0) return
            // najdi vsechny sourozence
            siblings = parentChildIds.map(
                (id) => selectObjects(objects).filter((object) => object.id === id)[0]
            )
        } else {
            siblings = selectObjects(objects).filter((object) => object.parentId === null)
        }

        // serad je podle indexu
        const orderedSiblings: AnySceneObjectT[] = sortObjects(siblings)

        // pokud se jejich index nerovna poradi, updatni jeho index
        orderedSiblings.forEach((sibling, index) => {
            if (sibling.index !== index) updIndexAction({ id: sibling.id, index: index })
        })
    }

export const filterAndSortSiblings = (
    objects: AnySceneObjectT[],
    parentId: string | null
): AnySceneObjectT[] => {
    const sortedObjects: AnySceneObjectT[] = sortObjects(objects)
    return sortedObjects.filter((sortedObject) => sortedObject.parentId === parentId)
}

export const filterObjectsByType = (
    objects: AnySceneObjectT[],
    type: string
): AnySceneObjectT[] => {
    return objects.filter((object) => object.type === type)
}

export const getObjectByTitle = (
    objects: AnySceneObjectT[],
    title: string
): AnySceneObjectT | undefined => {
    return objects.find((object) => object.title === title)
}

export const selectObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    Object.values(objects.value).map((object) => object)

export const selectRootObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    selectObjects(objects).filter((object) => object.parentId === null)

export const selectSortedObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    sortObjects(Object.values(objects.value).map((object) => object))

export const selectSortedRootObjects = (objects: ObjectsStateT): AnySceneObjectT[] =>
    sortObjects(Object.values(objects.value).map((object) => object)).filter(
        (object) => object.parentId === null
    )

export const selectObjectById = (objects: ObjectsStateT, id: string | null): AnySceneObjectT => {
    return id ? objects.value[id] : objects.value['']
}

export const selectObjectsByIds = (objects: ObjectsStateT, ids: string[]): AnySceneObjectT[] =>
    ids.map((id) => selectObjectById(objects, id))

export const clearTmpObjectsAsync =
    (graphicId: string): AppThunkT =>
    async (dispatch, getState) => {
        try {
            const tmpIds = selectUndoable(getState()).objects.tmpIds
            if (tmpIds.length > 0) {
                await del('files', undefined, {
                    key: `${S3_PREFIX}/${graphicId}/(images|sequences)/(${tmpIds.join('|')})`,
                })
            }
        } catch (error: any) {
            console.error(error)
        } finally {
            dispatch(resetTmpIds())
        }
    }
export const uploadObjectsAndAnimationsAsync =
    (objects: AnySceneObjectT[], animations: AnimationI[]): AppThunkT =>
    async (dispatch) => {
        //add all missing styles and attributes that are defined by a default object ... the object to be uploaded may miss some
        //properties which were defined by further development
        let upgraded = false
        objects.map((object) => {
            const defaultObjectValue = createObject([], defaultObject(object.type), null)
            defaultObjectValue.attributes.forEach((attribute) => {
                if (
                    !object.attributes.find((attr) => {
                        return attr.property === attribute.property
                    })
                ) {
                    object.attributes.push(attribute)
                    upgraded = true
                }
            })
            defaultObjectValue.styles.forEach((style) => {
                if (
                    !object.styles.find((stl) => {
                        return stl.property === style.property
                    })
                ) {
                    object.styles.push(style)
                    upgraded = true
                }
            })

            if (object.fill.length === 0) {
                defaultObjectValue.fill.forEach((fill) => {
                    if (
                        !object.fill.find((fll) => {
                            return fll.property === fill.property
                        })
                    ) {
                        object.fill.push(fill)
                        upgraded = true
                    }
                })
            }
            defaultObjectValue.filters.forEach((filter) => {
                if (
                    !object.filters.find((flt) => {
                        return flt.property === filter.property
                    })
                ) {
                    object.filters.push(filter)
                    upgraded = true
                }
            })
            defaultObjectValue.transforms.forEach((transform) => {
                if (
                    !object.transforms.find((tran) => {
                        return tran.property === transform.property
                    })
                ) {
                    object.transforms.push(transform)
                    upgraded = true
                }
            })
            return object
        })
        const { info } = useToast()
        if (upgraded) info('editor:graphicsUpgradedOnUpload')

        dispatch(uploadObjectsAndAnimationsAction({ objects, animations }))
    }

export const {
    uploadObjectsAndAnimationsAction,
    addObjAction,
    addMultiObjAction,
    updObjectAction,
    updateObjectsStylePropertyAction,
    updateObjectsDifferentStylesPropertyAction,
    updateObjectsPositionAction,
    updateObjectLockAspectRationPropertyAction,
    duplicateObjectsAction,
    updIndexAction,
    updParentIdAction,
    addChildIdAction,
    delChildIdAction,
    delMultiObjAction,
    delAnimationIdAction,
    addGroupStyleIdAction,
    delGroupStyleIdAction,
    delObjectAction,
    clearObjectsAction,
    moveSelectedHereAction,
    moveSelectedToRootAction,
    pushTmpIds,
    resetTmpIds,
    addAnimationAction,
    updateAnimationAction,
    createSequenceAnimationsAction,
    createImageAction,
    duplicateAnimationsAction,
    deleteAnimationsAction,
    durationAnimationAction,
    durationAnimationsAction,
    relativeDurationAnimationAction,
    relativeDurationAnimationsAction,
    startOffsetAnimationAction,
    startOffsetAnimationsAction,
    moveOffsetAnimationsAction,
} = objectsSlice.actions

export default objectsSlice.reducer

import SizeAndPosition from './ExpandableSections/SizeAndPosition'

interface Props {
    object: VideoPanel
    handleSetStyleValue: (property: any, value: any) => void
}

const VideoPanelTools = ({ object, handleSetStyleValue }: Props) => {
    return <SizeAndPosition object={object} handleSetValue={handleSetStyleValue} />
}

export default VideoPanelTools

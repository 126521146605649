import { backgroundColor, backgroundImage } from '../styles/groups/fill.styles'
import {
    matrix,
    rotateX,
    rotateY,
    rotateZ,
    scaleX,
    scaleY,
    transformOrigin,
    translateX,
    translateY,
} from '../styles/groups/transform.styles'
import {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
} from '../styles/simple/border.styles'
import {
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    color,
} from '../styles/simple/colorAndBackground.styles'
import { display, opacity, overflow } from '../styles/simple/display.styles'
import { alignItems, flexDirection, justifyContent } from '../styles/simple/flex.styles'
import {
    direction,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    textAlign,
    textTransform,
    whiteSpace,
} from '../styles/simple/font.styles'
import {
    left,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    position,
    top,
    zIndex,
} from '../styles/simple/position.styles'
import { height, width } from '../styles/simple/size.styles'

const padding = [paddingTop, paddingBottom, paddingLeft, paddingRight]
const margin = [marginTop, marginBottom, marginRight, marginLeft]
const background = [backgroundSize, backgroundRepeat, backgroundPosition]
const flex = [flexDirection, alignItems, justifyContent]
const borderRadius = [
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
]
const font = [
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    textAlign,
    textTransform,
    direction,
    whiteSpace,
]

export const defaultItemStyle: SimpleStyleT[] = [
    width,
    height,
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]

export const defaultTextStyle: SimpleStyleT[] = [
    ...font,
    width,
    height,
    top,
    left,
    position,
    zIndex,
    {
        ...display,
        value: 'flex',
    },
    ...padding,
    ...margin,
    ...flex,
    color,
    opacity,
    transformOrigin,
]

export const defaultImageStyle: SimpleStyleT[] = [
    width,
    height,
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]

export const defaultItemFills: GroupStyleI[] = [{ ...backgroundColor, index: 0 }]

export const defaultItemTransforms: GroupStyleI[] = [
    { ...scaleX, index: 0 },
    { ...scaleY, index: 1 },
    { ...translateX, index: 2 },
    { ...translateY, index: 3 },
    { ...rotateX, index: 4 },
    { ...rotateY, index: 5 },
    { ...rotateZ, index: 6 },
    { ...matrix, index: 7 },
    // skewX,
    // skewY,
]

export const defaultTextTransforms: GroupStyleI[] = defaultItemTransforms
export const defaultImageTransforms: GroupStyleI[] = []

export const defaultImageFills: GroupStyleI[] = [{ ...backgroundImage, index: 0 }]

export const defaultVideoPanelStyle = (widthValue: number, heightValue: number): SimpleStyleT[] => [
    { ...width, value: widthValue },
    { ...height, value: heightValue },
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]

export const defaultVideoPanelTransforms: GroupStyleI[] = [
    { ...scaleX, index: 0 },
    { ...scaleY, index: 1 },
    { ...translateX, index: 2 },
    { ...translateY, index: 3 },
    { ...rotateX, index: 4 },
    { ...rotateY, index: 5 },
    { ...rotateZ, index: 6 },
    { ...matrix, index: 7 },
    // skewX,
    // skewY,
]

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Status } from '../../constants/status'
import { del, postFile, put } from '../../helpers/ajax.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { GraphicT } from './graphic.slice'

interface ThumbnailStateT {
    data: ThumbnailT
    status?: string
}

const initialState: ThumbnailStateT = {
    data: {
        id: undefined,
        url: undefined,
    },
    status: Status.OK,
}

export interface ThumbnailT {
    id?: string
    url?: string
}

export const thumbnailSlice = createSlice({
    name: 'thumbnail',
    initialState,
    reducers: {
        getThumbnailPending: (state: ThumbnailStateT) => {
            state.data = initialState.data
            state.status = undefined
        },
        getThumbnailFulfilled: (state: ThumbnailStateT, action: PayloadAction<ThumbnailT>) => {
            state.status = Status.OK
            state.data = action.payload
        },
        getThumbnailRejected: (state: ThumbnailStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const { getThumbnailPending, getThumbnailFulfilled, getThumbnailRejected } =
    thumbnailSlice.actions

export const insertThumbnailAndSaveAsync =
    (graphicData: GraphicT, file: File, successMessage: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getThumbnailPending())

            const { publicUrl: url, _id } = await postFile(file, {
                storage: 's3',
                isPublic: true,
                hasFilename: true,
            })

            let newData = { ...graphicData, url, thumbnailId: _id }
            //update graphic without change reducer state graphic.data
            await put(`graphics/${newData._id}`, newData)

            dispatch(getThumbnailFulfilled({ url, id: _id }))
            const { success } = useToast()
            success(successMessage)
        } catch (error: any) {
            dispatch(getThumbnailRejected(error.message))
        }
    }

export const insertThumbnailBeforeSaveAsync =
    (file: File, successMessage: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getThumbnailPending())
            const { publicUrl: url, _id } = await postFile(file, {
                storage: 's3',
                isPublic: true,
                hasFilename: true,
            })

            dispatch(getThumbnailFulfilled({ url, id: _id }))
            const { success } = useToast()
            success(successMessage)
        } catch (error: any) {
            dispatch(getThumbnailRejected(error.message))
        }
    }

export const deleteThumbnailAsync =
    (thumbnailId?: string, graphicData?: GraphicT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getThumbnailPending())
            if (!graphicData?._id) {
                await del(`files/${thumbnailId}`, undefined)
            }

            if (graphicData?._id) {
                let newData = { ...graphicData, thumbnailId: undefined, url: undefined }
                //update graphic without change reducer state graphic.data
                await put(`graphics/${newData._id}`, newData)
            }
            dispatch(getThumbnailFulfilled({ id: undefined, url: undefined }))

            const { success } = useToast()
            success('graphics:successMessage.thumbnailDelete')
        } catch (error: any) {
            dispatch(getThumbnailRejected(error.message))
        }
    }

export default thumbnailSlice.reducer

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { AnimationTweens, VideoPanelTweens } from '../../../../data/defaults/tweens.styles.defaults'
import ExpandableSection from '../../../common/ExpandableSection'
// import { useAppSelector } from '../../../../hooks/useRedux'
// import { selectObjectById } from '../../../../store/slices/objects.slice'
import AnimationStates from './AnimationStates'

interface Props {
    animation: AnimationI
    handleSetValue: (property: any, value: any) => void
    object?: AnySceneObjectT
}

const AnimationTween = ({ animation, handleSetValue, object }: Props) => {
    // const object: AnySceneObjectT = useAppSelector(state => selectObjectById(state.objects, animation.objectId))
    // const canvas: CanvasI = useAppSelector(state => state.canvas)

    // const ItemTweenRelativeTo = animation.relativeTo ?? canvas

    const handleSetTweenType = (e: any) => {
        const tween = {
            ...animation.tween,
            type: e.target.value,
        }
        handleSetValue('tween', tween)
    }

    const handleSetTween = (e: any) => {
        // if (object.type === 'item') {
        //     const value = e.target.value
        //     const animationObject = dynamicAnimationTweens((object as ItemI), ItemTweenRelativeTo).find(a => a.name === value)

        //     if (animationObject)
        //         handleSetValue('tween', animationObject)
        // }
        // else if (object.type === 'text') {
        const value = e.target.value
        const animationObject = AnimationTweens.find((a) => a.name === value)

        if (animationObject) handleSetValue('tween', animationObject)
        //}
    }

    // const handleSetRelativeTo = (e: any) => {
    //     if (e.target.value === 'parent' && object.parentId)
    //         handleSetValue('relativeTo', object)
    //     else
    //         handleSetValue('relativeTo', canvas)
    // }

    const animationTweens = object?.type === 'video-panel' ? VideoPanelTweens : AnimationTweens
    return (
        <ExpandableSection
            expanded={true}
            title={'Animation'}
            component={
                <>
                    <FormControl fullWidth>
                        <InputLabel>tween</InputLabel>
                        {
                            // object.type === 'item' &&
                            // <Select
                            //     native
                            //     name="animation"
                            //     value={animation?.tween.name}
                            //     onChange={handleSetTween}
                            // >
                            //     {
                            //         dynamicAnimationTweens((object as ItemI), ItemTweenRelativeTo).map((animation: any, index: number) => {
                            //             return (
                            //                 <option
                            //                     key={index}
                            //                     value={animation.name}
                            //                 >
                            //                     {
                            //                         animation.name
                            //                     }
                            //                 </option>
                            //             )
                            //         })
                            //     }
                            // </Select>
                        }
                        {
                            // object.type === 'text' &&
                            <Select
                                native
                                name="animation"
                                value={animation?.tween.name}
                                onChange={handleSetTween}
                            >
                                {animationTweens.map((animation: any, index: number) => {
                                    return (
                                        <option key={index} value={animation.name}>
                                            {animation.name}
                                        </option>
                                    )
                                })}
                            </Select>
                        }
                    </FormControl>
                    {object && !['video-panel'].includes(object.type) && (
                        <FormControl fullWidth>
                            <InputLabel>type</InputLabel>
                            <Select
                                native
                                name="type"
                                value={animation?.tween.type}
                                onChange={handleSetTweenType}
                            >
                                <option value="fromTo">fromTo</option>
                                <option value="from">from</option>
                                <option value="to">to</option>
                                <option value="set">set</option>
                            </Select>
                        </FormControl>
                    )}

                    {
                        // animation.tween.isDynamic &&
                        // <FormControl
                        //     fullWidth
                        // >
                        //     <InputLabel>relative to</InputLabel>
                        //     <Select
                        //         native
                        //         name="relativeTo"
                        //         value={(animation.relativeTo && animation.relativeTo.type === 'item') ? 'parent' : 'canvas'}
                        //         onChange={handleSetRelativeTo}
                        //     >
                        //         <option value="canvas">canvas</option>
                        //         {
                        //             object.parentId !== null &&
                        //             <option value="parent">parent</option>
                        //         }
                        //     </Select>
                        // </FormControl>
                    }
                    {<AnimationStates animation={animation} type={animation?.tween.type} />}
                </>
            }
        />
    )
}

export default AnimationTween

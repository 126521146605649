import { v4 as uuidv4 } from 'uuid'
import { initializeGroupStyles } from '../../helpers/creators.helpers'
import { isImageEditable, isTextEditable, textSizing } from './objects.attributes.defaults'
import {
    defaultImageFills,
    defaultImageStyle,
    defaultImageTransforms,
    defaultItemFills,
    defaultItemStyle,
    defaultItemTransforms,
    defaultTextStyle,
    defaultTextTransforms,
    defaultVideoPanelStyle,
    defaultVideoPanelTransforms,
} from './objects.styles.defaults'

export const defaultCanvas: CanvasI = {
    id: '',
    type: 'canvas',
    width: 1920,
    height: 1080,
}

export const defaultItem: ItemI = {
    id: '',
    index: 0,
    title: 'Rectangle',
    isHidden: false,
    isLocked: false,
    type: 'item',
    parentId: '',
    childIds: [],
    animations: {},
    attributes: [isImageEditable],
    styles: defaultItemStyle,
    fill: defaultItemFills,
    filters: [],
    transforms: defaultItemTransforms,
}

type VideoPanelArgs = {
    width: number
    height: number
}

export const defaultVideoPanel = ({ width, height }: VideoPanelArgs): VideoPanel => {
    const id = 'i' + uuidv4()

    return {
        id,
        index: -1,
        title: 'Video Panel',
        isHidden: false,
        isLocked: false,
        type: 'video-panel',
        parentId: null,
        childIds: [],
        animations: {},
        attributes: [],
        styles: defaultVideoPanelStyle(width, height),
        fill: [],
        filters: [],
        transforms: initializeGroupStyles(id, defaultVideoPanelTransforms),
    }
}

export const defaultText: TextI = {
    id: '',
    index: 0,
    title: 'Text',
    isHidden: false,
    isLocked: false,
    type: 'text',
    text: 'Lorem ipsum',
    parentId: '',
    childIds: [],
    animations: {},
    attributes: [isTextEditable, textSizing],
    styles: defaultTextStyle,
    fill: [],
    filters: [],
    transforms: defaultTextTransforms,
}

export const defaultImage: ImageI = {
    id: '',
    index: 0,
    title: 'Image',
    isHidden: false,
    isLocked: false,
    type: 'image',
    parentId: '',
    childIds: [],
    animations: {},
    attributes: [isImageEditable],
    styles: defaultImageStyle,
    fill: defaultImageFills,
    filters: [],
    transforms: defaultImageTransforms,
}

export const defaultSequence: SequenceI = {
    id: '',
    index: 0,
    title: 'Image sequence',
    isHidden: false,
    isLocked: false,
    type: 'sequence',
    parentId: '',
    childIds: [],
    animations: {},
    attributes: [isImageEditable],
    styles: defaultImageStyle,
    fill: defaultImageFills,
    filters: [],
    transforms: defaultImageTransforms,
}

import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { UpdateObjectLockAspectRation } from './objectTypes'

export const updateObjectLockAspectRationPropertyBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateObjectLockAspectRation>
) => {
    const { object, width, height } = action.payload

    const widthStyles = object.styles.find((style) => style.property === 'width')
    const heightStyles = object.styles.find((style) => style.property === 'height')
    const restStyles = object.styles.filter(
        (style) => style.property !== 'width' && style.property !== 'height'
    )
    if (widthStyles && heightStyles) {
        state.value = {
            ...state.value,
            [object.id]: {
                ...object,
                styles: [
                    ...restStyles,
                    { ...widthStyles, value: width },
                    { ...heightStyles, value: height },
                ],
            },
        }
    }
}

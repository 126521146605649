import { useTheme } from '@mui/material'
import AbstractContainer from './AbstractContainer'

interface Props {
    object: VideoPanel
    isHiding: boolean
    canvasScaleCoef: number
    borderCoef: number
    isLocking: boolean
    handleSelectObject: (
        event: React.MouseEvent,
        objectId: string,
        animationIds: string[],
        ctrlCmdKey: boolean
    ) => void
}
const VideoPanelContainer = ({
    object,
    isHiding,
    canvasScaleCoef,
    borderCoef,
    handleSelectObject,
    isLocking,
}: Props) => {
    const theme = useTheme()

    return (
        <AbstractContainer
            style={{
                background: `repeating-linear-gradient(45deg, ${theme.palette.grey[900]}, ${theme.palette.grey[900]} 50px, ${theme.palette.grey[800]} 50px, ${theme.palette.grey[800]} 100px)`,
            }}
            object={object}
            isHiding={isHiding}
            isLocking={isLocking}
            canvasScaleCoef={canvasScaleCoef}
            borderCoef={borderCoef}
            handleSelectObject={handleSelectObject}
            className={'video-panel-container'}
            visibility={object.isHidden || isHiding ? 'hidden' : 'visible'}
        />
    )
}

export default VideoPanelContainer

import { v4 as uuidv4 } from 'uuid'
import { defaultAnimation } from '../data/defaults/animations.types.defaults'
import { defaultTimelineLabel } from '../data/defaults/timeline.types.defaults'
import {
    filterAndSortSiblings,
    filterObjectsByType,
    getObjectByTitle,
} from '../store/slices/objects.slice'

export const initializeGroupStyles = (
    objectId: string,
    initialStyles: GroupStyleT[]
): GroupStyleI[] =>
    initialStyles.map((style, index) => ({
        ...style,
        id: 's' + uuidv4(),
        objectId,
        index,
    }))

export const createObject = (
    objects: AnySceneObjectT[],
    initialObject: AnySceneObjectT,
    parentId: string | null,
    id: string = 'i' + uuidv4()
): AnySceneObjectT => {
    const index: number = filterAndSortSiblings(objects, parentId).length
    let titleindex: number = filterObjectsByType(objects, initialObject.type).length
    let title: string = initialObject.title + ' ' + titleindex

    while (getObjectByTitle(objects, title) !== undefined) {
        title = initialObject.title + ' ' + ++titleindex
    }

    const transforms: GroupStyleI[] = initializeGroupStyles(id, initialObject.transforms)
    return {
        ...initialObject,
        id,
        index,
        parentId,
        title,
        transforms,
    }
}

export const createAnimation = (
    objectId: string,
    startOffset: number,
    animation: AnimationI = defaultAnimation
): AnimationI => {
    const id: string = uuidv4()
    return {
        ...animation,
        id,
        objectId,
        delay: startOffset,
    }
}

export const createTimelineLabel = (
    time: number,
    type: TimelineLabelTypeT,
    title: string,
    jumpto?: string
): TimelineLabelI => {
    return {
        ...defaultTimelineLabel,
        title: title,
        time: time,
        type: type,
        jumpto: jumpto,
    }
}

export const createRefreshLabel = (time: number, slug: string): TimelineLabelI => {
    return {
        ...defaultTimelineLabel,
        title: slug,
        time: time,
        type: 'refresh',
        onLabelCode: {
            scope: 'label',
            type: 'init',
            code: `window.tweenly.refreshAsset("${slug}");`,
        },
    }
}

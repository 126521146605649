import { useMemo } from 'react'
import { selectUndoable } from '../../../helpers/selector.helpers'
import { useAnimationActions } from '../../../hooks/useAnimationActions'
import useObject from '../../../hooks/useObject'
import { useAppSelector } from '../../../hooks/useRedux'
import { selectAnimationById } from '../../../store/slices/objects.slice/animation/selectAnimationById'
import { selectAnimationsByIds } from '../../../store/slices/objects.slice/animation/selectAnimationsByIds'
import { ScrollableContainer } from '../../../style/styles'
import TitleHeading from '../../common/TitleHeading'
import AnimationTween from './AnimationTools/AnimationTween'
import Easing from './AnimationTools/Easing'
import Timing from './AnimationTools/Timing'

interface Props {
    animationId: string
    object?: AnySceneObjectT
}

const AnimationTools = ({ animationId, object }: Props) => {
    const { findObjectById } = useObject()
    const selectedAnimationIds = useAppSelector((state) => state.activeAnimation.selected)
    const objects = useAppSelector((state) => selectUndoable(state).objects)

    const animation: AnimationI | undefined = useMemo(() => {
        return objects.value
            ? selectAnimationById(Object.values(objects.value), animationId)
            : undefined
    }, [animationId, objects])

    const selectedAnimations = useMemo(() => {
        return selectAnimationsByIds(objects, selectedAnimationIds)
    }, [selectedAnimationIds, objects])

    const { updateAnimation } = useAnimationActions()
    const setValue = (property: any, value: any) => {
        if (animation) updateAnimation(animationId, animation.objectId, property, value)
    }
    const setValues = (animations: AnimationI[], property: any, value: any) => {
        animations.forEach((animation: AnimationI) =>
            updateAnimation(animation.id, animation.objectId, property, value)
        )
    }
    const isSequenceType = animation?.tween.name === 'sequence'

    const getSelectedAnimationsTitle = (): string => {
        let result: string[] = []
        selectedAnimations.forEach((animation: AnimationI) => {
            if (animation.tween.name === 'sequence') {
                let object = findObjectById(animation.objectId)
                if (object)
                    if (!result.includes(object?.title)) {
                        result.push(object?.title)
                    }
            } else {
                result.push(animation.tween.name)
            }
        })
        return result.join(', ')
    }

    return (
        <ScrollableContainer id="editor-step-4">
            {animation && (
                <div>
                    <TitleHeading
                        multiline={selectedAnimationIds.length > 1}
                        object={selectedAnimationIds.length <= 1 ? animation : undefined}
                        handleSetValue={selectedAnimationIds.length <= 1 ? setValue : undefined}
                        title={
                            selectedAnimationIds.length <= 1
                                ? undefined
                                : getSelectedAnimationsTitle()
                        }
                        disabled={isSequenceType}
                        isAnimation
                    />

                    <Timing animation={animation} selectedAnimations={selectedAnimations} />
                    {!isSequenceType &&
                        selectedAnimationIds.length < 2 &&
                        object &&
                        !['video-panel'].includes(object.type) && (
                            <Easing
                                animation={animation}
                                selectedAnimations={selectedAnimations}
                                handleSetValue={setValue}
                                handleSetValues={setValues}
                            />
                        )}
                    {!isSequenceType && selectedAnimationIds.length < 2 && (
                        <AnimationTween
                            animation={animation}
                            handleSetValue={setValue}
                            object={object}
                        />
                    )}
                </div>
            )}
        </ScrollableContainer>
    )
}

export default AnimationTools

import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { IconButton, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { setLockAspectRationAsync } from '../../../../store/slices/editor.slice'

export const LockAspectRatio = () => {
    const dispatch = useAppDispatch()
    const lockAspectRation = useAppSelector((state) => state.editor.value.settings.lockAspectRatio)

    const handleLockAspectRation = () => {
        dispatch(setLockAspectRationAsync(lockAspectRation))
    }

    return (
        <Tooltip
            title={
                lockAspectRation
                    ? t('editor:size&positionSettings.lockAspectRatioTooltip.unlock')
                    : t('editor:size&positionSettings.lockAspectRatioTooltip.lock')
            }
        >
            <IconButton
                color='secondary'
                onClick={handleLockAspectRation}
            >
                {lockAspectRation ? (
                    <LinkIcon style={{ transform: 'rotate(90deg)' }} />
                ) : (
                    <LinkOffIcon style={{ transform: 'rotate(90deg)' }} />
                )}
            </IconButton>
        </Tooltip>
    )
}

import {
    clearAllSelectedObjectsAction,
    clearObjectFromSelectedObjectsAction,
    setActiveObjectAction,
} from '../store/slices/activeObject.slice'

import {
    addChildIdAction,
    addObjAction,
    checkIndexes,
    createImageObjectAction,
    delChildIdAction,
    delObjectAction,
    getAllDescendantIds,
    updIndexAction,
    updObjectAction,
} from '../store/slices/objects.slice'
import { AppStateT } from '../store/store'
import { useAppDispatch, useAppSelector } from './useRedux'

import * as tweenly from 'tweenly'
import { Asset } from 'tweenly/dist/fetchAsset'
import { v4 as uuidv4 } from 'uuid'
import {
    defaultImage,
    defaultItem,
    defaultSequence,
    defaultText,
    defaultVideoPanel,
} from '../data/defaults/objects.types.defaults'
import { defaultEase } from '../data/styles/ease.styles'
import { sequence } from '../data/styles/tweens.styles'
import { FileResponse, postFile } from '../helpers/ajax.helpers'
import { createAnimation, createObject } from '../helpers/creators.helpers'
import { S3_PREFIX, getImageMetadata } from '../helpers/file.helpers'
import { swapChildIds } from '../helpers/hooks.helpers'
import { selectUndoable } from '../helpers/selector.helpers'
import { useObjectGroupActions } from '../hooks/useObjectGroupActions'
import {
    addSelectedAnimationsAction,
    clearSelectedAnimationsAction,
    removeSelectedAnimationsAction,
    setSelectedAnimationsForObjectAction,
} from '../store/slices/activeAnimation.slice'
import { CreateAssetT, createAssetAsync } from '../store/slices/assets.slice'
import { clear } from '../store/slices/editor.slice'
import { setPercentLoading } from '../store/slices/loading.slice'
import { updateMasterTimeline } from '../store/slices/masterTimeline.slice'
import {
    filterAndSortSiblings,
    selectObjectById,
    selectObjects,
} from '../store/slices/objects.slice'
import { useAnimationActions } from './useAnimationActions'
import { useCanvas } from './useCanvas'
import { useEditorSettings } from './useEditorSettings'
import useFramerate from './useFramerate'
import { useTimelineActions } from './useTimelineActions'

export const useObjectActions = () => {
    const { updateObjectGroups } = useObjectGroupActions()

    const state: AppStateT = useAppSelector((state: AppStateT) => state)
    const timeToSeek = useAppSelector((state) => state.masterTimeline.time)
    const assets = useAppSelector((state) => state.assets.data)

    const graphic = useAppSelector((state) => state.graphic.data)

    const objects: AnySceneObjectT[] = useAppSelector((state: AppStateT) =>
        selectObjects(selectUndoable(state).objects)
    )

    const selectedObjectIds: string[] = useAppSelector(
        (state: AppStateT) => state.activeObject.selected
    )

    const dispatch = useAppDispatch()

    const { addAnimations, createSequenceAnimations } = useAnimationActions()

    /* ACTIVE OBJECT HANDLERS */

    const setActiveObjectId = (id: string, animationsIds: string[]) => {
        dispatch(setActiveObjectAction({ id }))
        dispatch(setSelectedAnimationsForObjectAction({ animationsIds }))
    }

    const removeActiveObjectId = (object: AnySceneObjectT) => {
        dispatch(clearObjectFromSelectedObjectsAction({ id: object.id }))
        dispatch(removeSelectedAnimationsAction(Object.keys(object.animations)))
    }

    /* HELPER HANDLERS */

    const handleActiveObjectWhenCreating = (object: AnySceneObjectT) => {
        if (object && object.animations) {
            const animationIds = Object.keys(object.animations) ?? []
            setActiveObjectId(object.id, animationIds)
        } else {
            setActiveObjectId(object.id, [])
        }
    }

    const handleActiveObjectWhenDeleting = (object: AnySceneObjectT) => {
        // pokud je mazany objekt akttivni
        if (selectedObjectIds.includes(object.id))
            // deaktivuj ho
            removeActiveObjectId(object)
        // jinak
        else {
            // najdi vsechny potomky
            const ids: string[] = getAllDescendantIds(selectUndoable(state).objects, object.id)
            // a pripadneho aktivniho potomka deaktivuj
            ids.forEach((id) => {
                if (selectedObjectIds.includes(object.id)) removeActiveObjectId(object)
            })
        }
        // clear object.animations from selectedAnimationsIds
        dispatch(removeSelectedAnimationsAction(Object.keys(object.animations)))
    }

    // const addToParentsChildIds = (object: AnySceneObjectT) => {
    const addToParentsChildIds = (parentId: string | null, objectId: string) => {
        // pokud ma novy objekt odkaz na rodice
        if (parentId)
            // pridej k rodici id na novy objekt, ktery je jeho potomkem
            dispatch(addChildIdAction({ id: parentId, childId: objectId }))
    }

    const deleteFromParentsChildIds = (object: AnySceneObjectT) => {
        // pokud je mazany objekt potomkem nektereho objektu a jeho rodic existuje
        if (object.parentId && selectObjectById(selectUndoable(state).objects, object.parentId))
            // smaz jeho referenci u daneho rodice
            dispatch(delChildIdAction({ id: object.parentId, childId: object.id }))
    }

    const isParentObjectType = (type: string): boolean => {
        return type === 'item' || type === 'image'
    }

    /* MAIN HANDLERS */
    const addObject = (object: AnySceneObjectT): AnySceneObjectT | undefined => {
        if (object.parentId) {
            const parentobject = selectObjectById(selectUndoable(state).objects, object.parentId)
            if (!isParentObjectType(parentobject.type)) return undefined
        }
        dispatch(addObjAction({ object: object }))
        addToParentsChildIds(object.parentId, object.id)
        //clear all selected animations
        dispatch(clearSelectedAnimationsAction())
        handleActiveObjectWhenCreating(object)
        dispatch(updateMasterTimeline(timeToSeek))
        return object
    }

    const addItem = (): AnySceneObjectT | undefined =>
        addObject(createObject(objects, defaultItem, null))

    const { canvasResolution } = useCanvas()
    const addVideoPanel = (): AnySceneObjectT | undefined =>
        addObject(
            defaultVideoPanel({ width: canvasResolution.width, height: canvasResolution.height })
        )

    const addText = (): AnySceneObjectT | undefined =>
        addObject(createObject(objects, defaultText, null))
    const addImage = (id?: string): AnySceneObjectT | undefined => {
        const object = createObject(objects, defaultImage, null, id)
        return addObject(object)
    }
    const addSequence = (id?: string): AnySceneObjectT | undefined => {
        const object = createObject(objects, defaultSequence, null, id)
        return addObject(object)
    }

    const addChildItem = (parentId: string): AnySceneObjectT | undefined => {
        const parentobject = selectObjectById(selectUndoable(state).objects, parentId)
        if (!isParentObjectType(parentobject.type)) return undefined
        return addObject(createObject(objects, defaultItem, parentId))
    }

    const addChildText = (parentId: string) => {
        const parentobject = selectObjectById(selectUndoable(state).objects, parentId)
        if (!isParentObjectType(parentobject.type)) return undefined

        return addObject(createObject(objects, defaultText, parentId))
    }

    const addChildImage = (parentId: string, type: 'image' | 'sequence', id?: string) => {
        const parentobject = selectObjectById(selectUndoable(state).objects, parentId)
        if (!isParentObjectType(parentobject.type)) return undefined

        return addObject(
            createObject(objects, type === 'image' ? defaultImage : defaultSequence, parentId, id)
        )
    }

    const duplicateObjectsAnimations = (objectId: string, sampleAnimations: AnimationI[]) => {
        const animations: AnimationI[] = sampleAnimations.map((sampleAnimation) => {
            return createAnimation(objectId, sampleAnimation.delay, sampleAnimation)
        })
        //to do refactoring addAnimation when duplicate objects
        if (animations.length > 0) addAnimations(animations)
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const updateObject = (id: string, property: any, value: any) => {
        dispatch(updObjectAction({ id: id, property: property, value: value }))
    }

    const updateObjectAttribute = (object: AnySceneObjectT, property: string, value: boolean) => {
        const attribute: AttributeT | undefined = object.attributes.find(
            (style) => style.property === property
        )
        if (!attribute) return

        const restAttributes: AttributeT[] = object.attributes.filter(
            (style) => style.property !== property
        )
        const updatedAttributes: AttributeT[] = [
            ...restAttributes,
            {
                ...attribute,
                value: value,
            },
        ]
        updateObject(object.id, 'attributes', updatedAttributes)
    }

    const updateObjectStyle = (object: AnySceneObjectT, property: string | number, value: any) => {
        const style: SimpleStyleT | undefined = object.styles.find(
            (style) => style.property === property
        )
        if (!style) return
        if (style.value === value && style.property === property) {
            return
        } else {
            const restStyles: SimpleStyleT[] = object.styles.filter(
                (style) => style.property !== property
            )
            const updatedStyles: SimpleStyleT[] = [
                ...restStyles,
                {
                    ...style,
                    value: value,
                },
            ]
            updateObject(object.id, 'styles', updatedStyles)
        }
    }

    const updateObjectStyles = (object: AnySceneObjectT, properties: Record<string, any>) => {
        let newStyles: SimpleStyleT[] = object.styles.slice()

        Object.entries(properties).forEach(([property, value]) => {
            const style: SimpleStyleT | undefined = newStyles.find(
                (style) => style.property === property
            )
            if (style) {
                newStyles = newStyles.map((style) => {
                    if (style.property === property) {
                        return {
                            ...style,
                            value: value,
                        }
                    } else {
                        return style
                    }
                })
            }
        })

        updateObject(object.id, 'styles', newStyles)
    }

    const deleteObject = (object: AnySceneObjectT) => {
        handleActiveObjectWhenDeleting(object)
        deleteFromParentsChildIds(object)

        // smaz objekt
        dispatch(delObjectAction({ id: object.id }))
        dispatch(checkIndexes(object.parentId))
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const clearScene = () => {
        dispatch(clear())
    }

    const moveObjectUp = (object: AnySceneObjectT) => moveObject(object, -1)
    const moveObjectDown = (object: AnySceneObjectT) => moveObject(object, +1)

    const { refreshMasterTimeline } = useTimelineActions()

    const moveObject = (object: AnySceneObjectT, i: number) => {
        const siblings = filterAndSortSiblings(objects, object.parentId)
        const index = objects.filter((x) => x.parentId === object.parentId).indexOf(object) + i
        if (index >= 0 && index < siblings.length) {
            const siblingIds: string[] = siblings.map((s) => s.id)
            const siblingId: string = siblingIds[index]

            if (object.parentId) {
                const childIds: string[] = swapChildIds(siblingIds, object.id, siblingId)
                // updatni childIds v nadrazenem objektu
                updateObject(object.parentId, 'childIds', childIds)
            }
            dispatch(updIndexAction({ id: object.id, index: index }))
            dispatch(updIndexAction({ id: siblingId, index: object.index }))
        }
        //refresh timeline with animation after move up/down,
        //setTimeout because update index of object is async function
        setTimeout(() => refreshMasterTimeline(), 100)
    }

    const createSequence = (parentid: string | null) =>
        parentid ? addChildImage(parentid, 'sequence') : addSequence()

    const setFillProperty = (object: ImageI, index: number, property: string, value: any) => {
        const updatedGroups = updateObjectGroups(object['fill'], index, property, value)
        updateObject(object.id, 'fill', updatedGroups)
    }

    const processImage = (
        parentObjectId: string | null,
        url: string,
        attribute?: string,
        objectId?: string
    ) => {
        const item = createObject(objects, defaultImage, parentObjectId, objectId)
        if (!item) {
            console.log('Cannot create an object')
            return
        }
        const img = new Image()
        img.addEventListener('load', function () {
            dispatch(
                createImageObjectAction(
                    {
                        object: item,
                        dimensions: { width: img.width, height: img.height },
                        fill: updateObjectGroups(
                            item['fill'],
                            0,
                            'value',
                            attribute ? attribute : `url("${img.src}")`
                        ),
                    },
                    selectNewObject
                )
            )
        })
        img.src = url
    }

    const selectNewObject = (id: string) => {
        dispatch(clearSelectedAnimationsAction())
        dispatch(clearAllSelectedObjectsAction())
        dispatch(setActiveObjectAction({ id: id }))
    }

    const { companyId } = useEditorSettings()

    const readImageFile = async (file: File, parentObjectId: string | null) => {
        const item = createObject(objects, defaultImage, parentObjectId)
        if (!item) {
            console.log('Cannot create an object')
            return
        }

        const img = new Image()
        img.addEventListener('load', function () {
            dispatch(
                createImageObjectAction(
                    {
                        object: item,
                        dimensions: { width: img.width, height: img.height },
                        fill: updateObjectGroups(item['fill'], 0, 'value', `url("${img.src}")`),
                    },
                    selectNewObject
                )
            )
        })

        const graphicId = graphic._id ?? graphic.tmpId
        const fileMetadata = await postFile(file, {
            storage: 's3',
            key: `${S3_PREFIX}/${graphicId}/images/${item.id}`,
            isPublic: true,
            hasFilename: true,
        })
        img.src = fileMetadata.publicUrl!
        const asset: CreateAssetT = {
            name: file.name,
            slug: item.id,
            companyId,
            attributes: [],
            url: fileMetadata.publicUrl!,
            type: 'image',
            size: fileMetadata.length,
            description: '',
        }
        dispatch(createAssetAsync(asset, companyId))
    }

    const readImageFileFromURL = async (
        url: string,
        parentObjectId: string | null,
        objectId?: string
    ) => {
        processImage(parentObjectId, url, undefined, objectId)
    }

    const readImageFileFromAsset = async (
        attributeImgUrl: string,
        parentObjectId: string | null
    ) => {
        const url = await tweenly.processField(attributeImgUrl, assets as Asset[])
        processImage(parentObjectId, url!, attributeImgUrl)
    }

    const replaceImageSource = async (file: File, object: AnySceneObjectT) => {
        const graphicId = graphic._id ?? graphic.tmpId
        const fileMetadata = await postFile(file, {
            storage: 's3',
            key: `${S3_PREFIX}/${graphicId}/images/${object.id}`,
            isPublic: true,
            hasFilename: true,
        })
        const asset: CreateAssetT = {
            name: object.title,
            slug: object.id,
            companyId,
            attributes: [],
            url: fileMetadata.publicUrl!,
            type: 'image',
            size: fileMetadata.length,
            description: '',
        }
        dispatch(createAssetAsync(asset, companyId))

        setFillProperty(object as ImageI, 0, 'value', `url("${fileMetadata.publicUrl!}")`)
    }

    const replaceImageSourceFromUrl = async (url: string, object: AnySceneObjectT) => {
        setFillProperty(object as ImageI, 0, 'value', `url("${url}")`)
    }
    const replaceImageSourceFromAsset = async (attribute: string, object: AnySceneObjectT) => {
        setFillProperty(object as ImageI, 0, 'value', attribute)
    }

    const { setFramerateValue } = useFramerate()
    const readImageSequence = async (files: FileList, parentObjectId: string | null) => {
        const step = setFramerateValue(1)

        const item = parentObjectId
            ? createObject(objects, defaultSequence, parentObjectId)
            : createObject(objects, defaultSequence, null)

        if (!item) {
            return
        }

        const graphicId = graphic._id ?? graphic.tmpId
        const { width } = await getImageMetadata(files[0])

        let length = 0
        const filesMetadata: FileResponse[] = []
        for (let i = 0; i < files.length; i++) {
            const metadata = await postFile(files[i], {
                storage: 's3',
                key: `${S3_PREFIX}/${graphicId}/sequences/${item.id}`,
                isPublic: true,
                thumbnailWidth: Math.round(width / 2),
                hasFilename: true,
            })
            length += metadata.length
            filesMetadata.push(metadata)
            dispatch(setPercentLoading((i / files.length) * 100))
        }

        const metadata = filesMetadata[Math.floor(files.length / 2)]
        const asset: CreateAssetT = {
            name: metadata.filename,
            slug: item.id,
            companyId,
            attributes: [],
            type: 'sequence',
            size: length,
            url: metadata.publicUrl,
            urls: filesMetadata.map((file) => file.publicUrl!),
            description: '',
        }
        dispatch(createAssetAsync(asset, companyId))

        const animations = filesMetadata
            .sort((f1, f2) => f1.filename.localeCompare(f2.filename))
            .map((f, index) => {
                const animation: AnimationI = {
                    id: uuidv4(),
                    target: null,
                    title: f.filename,
                    objectId: item.id,
                    duration: step,
                    delay: index * step,
                    repeat: 0,
                    tween: sequence(f.publicUrl!),
                    relativeTo: null,
                    ease: defaultEase,
                    onInitCode: null,
                    onUpdateCode: null,
                }
                return animation
            })

        const img = new Image()
        img.addEventListener('load', function () {
            createSequenceAnimations(animations, item, { width: img.width, height: img.height })
        })
        img.src = filesMetadata[0].publicUrl!
        const animationIds: string[] = animations.map((animation) => animation.id)
        dispatch(clearSelectedAnimationsAction())
        dispatch(clearAllSelectedObjectsAction())
        dispatch(setActiveObjectAction({ id: item.id }))
        dispatch(addSelectedAnimationsAction(animationIds))
    }

    const deselectObjects = () => {
        dispatch(clearSelectedAnimationsAction())
        dispatch(clearAllSelectedObjectsAction())
    }

    const videoPanelExists = objects.some((o) => o.type === 'video-panel')

    return {
        isParentObjectType,
        addItem,
        addText,
        addImage,
        addObject,
        addChildItem,
        addChildText,
        addChildImage,
        updateObject,
        updateObjectAttribute,
        updateObjectStyle,
        updateObjectStyles,
        deleteObject,
        clearScene,
        setActiveObjectId,
        removeActiveObjectId,
        moveObjectUp,
        moveObjectDown,
        addToParentsChildIds,
        duplicateObjectsAnimations,
        readImageFile,
        readImageFileFromURL,
        readImageFileFromAsset,
        readImageSequence,
        replaceImageSource,
        replaceImageSourceFromUrl,
        replaceImageSourceFromAsset,
        deselectObjects,
        createSequence,
        addVideoPanel,
        videoPanelExists,
    }
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Asset, AssetType } from 'tweenly/dist/fetchAsset'
import { Status } from '../../constants/status'
import { get, post, postFile, put } from '../../helpers/ajax.helpers'
import { S3_PREFIX } from '../../helpers/file.helpers'
import { getAssetTypeMsg } from '../../helpers/graphic.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { CompanyT } from './company.slice'
import { addAssetsFulfilled } from './companyStats.slice'
import { AssetRef } from './graphics.slice'
import { UserT } from './user.slice'
import * as tweenly from 'tweenly'

export type CreateAssetT = {
    name: string
    slug: string
    url?: string
    type?: AssetType
    attributes: AttributesT[]
    companyId: string
    size?: number
    description: string
    tags?: string[]
    urls?: string[]
}
export type AttributesT = {
    path: string
    name: string
}
export type AssetT = CreateAssetT & {
    _id: string
    userId?: string
    isPublished?: boolean
    created: Date
    user?: UserT | null
    updated: Date
    updatedById?: string
    price: number
    revision?: number
    updatedBy?: UserT | null
    company?: CompanyT
    isFinal?: boolean
    isExported?: boolean
    thumbnailId?: string
    fileId?: string
    tmpId?: string
    assetsRef?: AssetRef[]
}

type AssetsStateT = {
    data: AssetT[]
    status?: string
}
const initialState: AssetsStateT = {
    data: [],
}
export const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        getAssetsPending: (state: AssetsStateT) => {
            state.data = initialState.data
            state.status = undefined
        },
        getAssetsDeletePending: (state: AssetsStateT) => {
            state.status = undefined
        },
        createAssetsFulfilled: (state: AssetsStateT, action: PayloadAction<AssetT[]>) => {
            state.status = Status.OK
            state.data = [...state.data, ...action.payload]
        },
        updateAssetFulfilled: (state: AssetsStateT, action: PayloadAction<AssetT>) => {
            state.status = Status.OK
            state.data = state.data.map((asset: AssetT) => {
                if (asset._id === action.payload._id) {
                    return {
                        ...asset,
                        name: action.payload.name,
                        attributes: action.payload.attributes,
                    }
                } else {
                    return asset
                }
            })
        },
        deleteAssetFulfilled: (state: AssetsStateT, action: PayloadAction<{ _id: string }>) => {
            state.data = state.data.filter((asset) => asset._id !== action.payload._id)
        },
        getAssetsFulfilled: (state: AssetsStateT, action: PayloadAction<AssetT[]>) => {
            state.status = Status.OK
            state.data = action.payload
        },
        getAssetsRejected: (state: AssetsStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const {
    getAssetsPending,
    getAssetsDeletePending,
    createAssetsFulfilled,
    updateAssetFulfilled,
    deleteAssetFulfilled,
    getAssetsFulfilled,
    getAssetsRejected,
} = assetsSlice.actions

export const createFontsAsync =
    (files: File[], companyId: string, cb?: (asset: AssetT) => void): AppThunkT =>
    async (dispatch, getState) => {
        const { graphic } = getState()
        const graphicId = graphic.data._id ?? graphic.data.tmpId

        try {
            const assets = await Promise.all(
                files.map(async (file) => {
                    const fileMetadata = await postFile(file, {
                        storage: 's3',
                        isPublic: true,
                        hasFilename: true,
                        key: `${S3_PREFIX}/${graphicId}/fonts`,
                    })

                    const data: CreateAssetT = {
                        name: fileMetadata.filename.substring(
                            0,
                            fileMetadata.filename.lastIndexOf('.')
                        ),
                        slug: fileMetadata._id,
                        companyId,
                        attributes: [],
                        url: fileMetadata.publicUrl!,
                        type: 'font',
                        size: fileMetadata.length,
                        description: '',
                    }
                    const { _id } = await post<{ _id: string }>(`assets`, data)
                    const asset: AssetT = {
                        ...data,
                        _id,
                        companyId,
                        created: new Date(),
                        updated: new Date(),
                        price: 0,
                    }
                    return asset
                })
            )

            cb?.(assets[0])
            dispatch(createAssetsFulfilled(assets))
            dispatch(addAssetsFulfilled({ id: companyId, assets }))
        } catch (error: any) {
            dispatch(getAssetsRejected(error.message))
        }
    }

const createAsset = async (data: CreateAssetT, companyId: string) => {
    let asset: AssetT
    const { _id } = await post<{ _id: string }>(`assets`, { ...data, companyId })
    asset = {
        ...data,
        _id,
        created: new Date(),
        updated: new Date(),
        price: 0,
    }
    return asset
}

export const createAssetAsync =
    (data: CreateAssetT, companyId: string): AppThunkT =>
    async (dispatch) => {
        try {
            const asset = await createAsset(data, companyId)
            dispatch(createAssetsFulfilled([asset]))
            const { success } = useToast()
            const assetTypeMsg = asset.type ? getAssetTypeMsg(asset.type) : ''
            success('dataSource:successMessage.assetsCreate' + assetTypeMsg)
        } catch (error: any) {
            dispatch(getAssetsRejected(error.message))
        }
    }

export const updateAssetAsync =
    (id: string, asset: AssetT): AppThunkT =>
    async (dispatch) => {
        try {
            await put<AssetT>(`assets/${id}`, asset)
            dispatch(updateAssetFulfilled(asset))
            const { success } = useToast()
            success('dataSource:successMessage.assetsUpdate')
        } catch (error: any) {
            dispatch(getAssetsRejected(error.message))
        }
    }

/**
 * soft delete asset, only from state in slice
 */
export const softDeleteAssetAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getAssetsDeletePending())
            dispatch(deleteAssetFulfilled({ _id: id }))
        } catch (error: any) {
            dispatch(getAssetsRejected(error.message))
        }
    }

export const getGraphicAssetsAsync =
    (localAssets: AssetT[], companyId: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getAssetsPending())
            if (localAssets.length) {
                const assets: AssetT[] = await get<AssetT[]>(`assets`, {
                    id: localAssets.map((asset) => asset._id),
                })
                await Promise.all(
                    localAssets
                        .filter(
                            (localAsset) => !assets.some((asset) => asset._id === localAsset._id)
                        )
                        .map((asset) => createAsset(asset, companyId))
                )

                await tweenly.initAssets(localAssets as Asset[])
                dispatch(getAssetsFulfilled(localAssets))
            } else {
                dispatch(getAssetsFulfilled([]))
            }
        } catch (error: any) {
            dispatch(getAssetsRejected(error.message))
        }
    }

export default assetsSlice.reducer
